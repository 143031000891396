/* CustomScroll.css */
.content {
  width: 100%;
  max-height: 100vh;
  overflow: hidden;
  position: relative;
}

.custom-scrollbar {
  position: absolute;
  top: 15%;
  left: 25px;
  width: 5px;
  height: 70%;
  border-radius: 2px;
  cursor: pointer;
  background-color: #ccc;
}

.scroll-thumb {
  width: 100%;
  transition: transform 0.2s ease;
  height: 170px;
  background-color: #09447a;
  cursor: pointer;
  border-radius: 2px;
}


@media (max-width : 500px) {
  .scrollable-container{
    display: none;
  }
}