@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;1,300&display=swap");

*::before,
*::after {
  margin: 0;
  padding: 0;
}
body::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
  border-radius: 0;
}

body::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 0;
}

.odd {
  display: flex;
  gap: 73px;
  margin-top: -122.5px;
  margin-left: 93px;
  justify-content: center;
  align-items: center;
}

.even {
  display: flex;
  margin-left: -160px;
  margin-top: -123px;
  gap: 73px;
  justify-content: center;
  align-items: center;
}

.vectorsvg {
  width: 180px;
  height: 195px;
  background-image: url(".././src/assets/Vector.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.aboutUs {
  width: 180px;
  height: 150px;
  margin-top: -47px;
  background-image: url("../src/assets/aboutus.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.roboBarista {
  width: 180px;
  height: 150px;
  margin-top: -46px;
  background-image: url("../src/assets/RoboBarista.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.smartCard {
  width: 180px;
  height: 150px;
  margin-top: -46px;
  background-image: url("../src/assets/smartCards.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.gidroGel {
  width: 180px;
  height: 150px;
  margin-top: -46px;
  background-image: url("../src/assets/gidroGel.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.Restaurants{
  width: 180px;
  height: 150px;
  margin-top: -46px;
  background-image: url("../src/assets/Restaurants.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.SwissMedical{
  width: 180px;
  height: 150px;
  margin-top: -46px;
  background-image: url("../src/assets/SwissMedical.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.RealEstate{
  width: 180px;
  height: 150px;
  margin-top: -46px;
  background-image: url("../src/assets/RealEstate.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.roboCarWash {
  width: 180px;

  height: 150px;
  margin-top: -46px;
  background-image: url("../src/assets/RoboCarWash.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.ForEvents{
  width: 180px;

  height: 150px;
  margin-top: -46px;
  background-image: url("../src/assets/ForEvents.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.RentACar {
  width: 180px;
  height: 150px;
  margin-top: -46px;
  background-image: url("../src/assets/RentACar.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.Contacts {
  width: 180px;
  height: 150px;
  margin-top: -46px;
  background-image: url("../src/assets/contacts.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.main-animation {
  animation: mainHome 3s ease;
}

.main-animation__text {
  animation: mainHomeText 3s ease;
}

@keyframes mainHome {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    display: none;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
  }
}

@keyframes mainHomeText {
  0% {
    opacity: 1;
    transform: translateY(20px);
  }
  60% {
    opacity: 1;
    display: none;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@media (max-height: 900px) {
  .media-container{
    display: none;
  }
  .nfc {
    position: absolute;
    left: 25%;
    right: 20%;
    bottom: 50%;
    transition: 1s;
  }
  #vector {
    background-color: #09447a;
    margin-top: -1px;
    max-width: 100vw;
    max-height: 140vh;
    object-fit: cover;
    overflow: hidden;
  }
}
@media (max-height: 800px) {
  .media-container{
    display: none;
  }
  .nfc {
    position: absolute;
    left: 25%;
    right: 20%;
    bottom: 50%;
    transition: 1s;
  }
  #vector {
    background-color: #09447a;
    margin-top: -1px;
    max-width: 100vw;
    max-height: 150vh;
    object-fit: cover;
    overflow: hidden;
  }
}

@media (max-height: 700px) {
  .media-container{
    display: none;
  }
  .nfc {
    position: absolute;
    left: 25%;
    right: 20%;
    bottom: 50%;
    transition: 1s;
  }
  #vector {
    background-color: #09447a;
    margin-top: -1px;
    max-width: 100vw;
    max-height: 200vh;
    object-fit: cover;
    overflow: hidden;
  }
}

@media (max-height: 600px) {
  .media-container{
    display: none;
  }
  .nfc {
    position: absolute;
    left: 25%;
    right: 20%;
    bottom: 50%;
    transition: 1s;
  }
  #vector {
    background-color: #09447a;
    margin-top: -1px;
    max-width: 100vw;
    max-height: 200vh;
    object-fit: cover;
    overflow: hidden;
  }
}

@media (max-width: 500px) {
  .media-container{
    display: block;
  }
  .nfc {
    position: absolute;
    left: 15%;
    right: 20%;
    bottom: 50%;
    transition: 1s;
  }

  .odd {
    gap: 54px;
    margin-left: 55.3px;
    margin-top: -106px;
  }
  .even {
    margin-left: 243px;
    margin-top: -107px;
    gap: 54px;
  }

  #vector {
    margin-top: -10px;
    margin-left: -53px;
    max-width: 134vw;
    max-height: 150vh;
    object-fit: cover;
    overflow: hidden;
  }

  .vectorsvg {
    width: 134px;
    height: 160px;
  }
  .aboutUs {
    width: 134px;
    height: 114px;

  }
  .roboBarista {
    width: 134px;
    height: 114px;

  }
  .Restaurants{
    width: 134px;
    height: 114px;
  }
  .smartCard {
    width: 134px;
    height: 114px;

  }
  .gidroGel {
    width: 134px;
    height: 114px;

  }
  .roboCarWash {
    width: 134px;
    height: 114px;

  }
  .ForEvents{
    width: 134px;
    height: 114px;
  }
  .SwissMedical{
    width: 134px;
    height: 114px;
  }
  .RealEstate{
    width: 134px;
    height: 114px;
  }
  .RentACar {
    width: 134px;
    height: 114px;

  }
  .Contacts {
    width: 134px;
    height: 114px;

  }
}
